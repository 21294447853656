<template>
  <v-skeleton-loader
    type="image"
    class="address"
    v-if="!address"
  />
  <v-card
    class="address"
    v-else
  >
    <v-tabs
      v-model="tabs"
      class="border-b"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        href="#address"
        class="primary--text"
      >
        {{$t('t.Address')}}
      </v-tab>

      <v-tab
        href="#dunning-address"
        class="primary--text"
      >
        {{$t('t.DunningAddress')}}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      class="transparent"
      v-model="tabs"
    >
      <v-tab-item value="address">
        <v-card-text>
          <div class="d-flex flex-column">
            <v-text-field
              :label="$t('t.Address1')"
              readonly
              v-model="address.address1"
              class="address1"
            />
            <v-text-field
              v-if="address.address2"
              :label="$t('t.Address2')"
              readonly
              v-model="address.address2"
              class="address2"
            />
            <v-text-field
              v-if="address.address3"
              :label="$t('t.Address3')"
              readonly
              v-model="address.address3"
              class="address3"
            />

            <div class="d-flex flex-row">
              <v-text-field
                :label="$t('t.City')"
                readonly
                v-model="address.city"
              />
              <v-text-field
                :label="$t('t.StateProvince')"
                readonly
                v-model="address.stateProvince"
              />
              <v-text-field
                :label="$t('t.PostalCode')"
                readonly
                v-model="address.postalCode"
              />
              <v-text-field
                :label="$t('t.Country')"
                readonly
                v-model="address.country"
              />
            </div>
            <div class="d-flex flex-row">
              <v-text-field
                v-if="account.phone1"
                :label="$t('t.Phone')"
                readonly
                :value="account.phone1"
              >
                <template v-slot:prepend>
                  <phone-btn
                    :phone-number="account.phone1"
                    :show-phone-number="false"
                    normal
                  />
                </template>
              </v-text-field>
              <v-text-field
                v-if="account.phone2"
                :label="$t('t.Phone2')"
                readonly
                :value="account.phone2"
              >
                <template v-slot:prepend>
                  <phone-btn
                    :phone-number="account.phone2"
                    :show-phone-number="false"
                    normal
                  />
                </template>
              </v-text-field>
              <v-text-field
                v-if="account.fax"
                :label="$t('t.Fax')"
                readonly
                :value="account.fax"
              />
            </div>
          </div>
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="dunning-address">
        <v-card-text>
          <v-text-field
            :label="$t('t.Address1')"
            v-model="dunningAddress.address1"
            @input="updateDunningAddress"
            :rules="length"
            class="address1"
          />
          <v-text-field
            :label="$t('t.Address2')"
            v-model="dunningAddress.address2"
            @input="updateDunningAddress"
            :rules="maxLength(200)"
            class="address2"
          />
          <v-text-field
            :label="$t('t.Address3')"
            v-model="dunningAddress.address3"
            @input="updateDunningAddress"
            :rules="maxLength(200)"
            class="address3"
          />
          <v-text-field
            :label="$t('t.City')"
            v-model="dunningAddress.city"
            @input="updateDunningAddress"
            :rules="maxLength(100)"
          />
          <v-text-field
            :label="$t('t.StateProvince')"
            v-model="dunningAddress.stateProvince"
            @input="updateDunningAddress"
            :rules="maxLength(50)"
          />
          <v-text-field
            :label="$t('t.PostalCode')"
            v-model="dunningAddress.postalCode"
            @input="updateDunningAddress"
            :rules="maxLength(20)"
          />
          <v-text-field
            :label="$t('t.Country')"
            v-model="dunningAddress.country"
            @input="updateDunningAddress"
            :rules="maxLength(50)"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  components: {
    PhoneBtn: () => import('@/components/phone-btn')
  },
  data () {
    return {
      dataDunningAddress: {},
      tabs: 'address',
      minLength: [v => !v || v.length === 0 || v.length >= 3 || this.$t('t.MinLength', { value: 3 })]
    }
  },
  methods: {
    maxLength (max) {
      return [v => !v || v.length <= max || this.$t('t.MaxLength', { value: max })]
    },
    updateDunningAddress () {
      this.$nextTick(() => {
        this.$emit('update:account',
          this.lodash.cloneDeep(Object.assign({}, this.account, { dunningAddress: this.dunningAddress }))
        )
      })
    }
  },
  computed: {
    address () {
      return this.account?.address ?? {}
    },
    dunningAddress () {
      return this.dataDunningAddress
    },
    length () {
      return [...this.minLength, ...this.maxLength(200)]
    }
  },
  props: {
    account: Object
  },
  watch: {
    account: {
      handler (v) {
        this.dataDunningAddress = v.dunningAddress ? this.lodash.cloneDeep(v.dunningAddress) : {}
      },
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.border-b
  border-bottom 1px #00000020 solid

.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.address
  min-width 10em

.address1, .address2, .address3
  width 60em

.addressTab
  margin-left 0

.v-text-field
  margin-right 1em
</style>
